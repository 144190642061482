import React, { useState, useEffect, useRef, useMemo } from "react";
import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import moment from 'moment';
import Fade from "@mui/material/Fade";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import TextField, { TextFieldProps } from "@mui/material/TextField";
import { styled } from "@mui/material/styles";
import Radio, { RadioProps } from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import CountryDropdown from "../../layouts/countryDropdown/CountryDropdown";
import FormLabel from "@mui/material/FormLabel";
import { useDebounce } from 'usehooks-ts'
import { getCampaignsData } from "../../apis/campaigns/campaigns";
import { contactDetails } from "../../apis/contacts/contactsApi";
import InputAdornment from '@mui/material/InputAdornment';
import CircularProgress from '@mui/material/CircularProgress';
import {
  setChatShowChat
} from "../../app/slice/chatSlice";
import Autocomplete from "@mui/material/Autocomplete";
import {
  useGetPrescribersQuery,
  useAddContactQuery,
} from "../../utils/ApiContacts";
import DropdownInupt from "../../components/shared/Dropdown";
// import ReactAutocomplete from "react-autocomplete";
import SearchInput from "../../layouts/searchInput/SearchInput";
import config from "../../env.json";
// import ReactAutocomplete from "react-autocomplete";
import AssociatedPresList from "../../layouts/AssociatedPrescriber/AssociatedPresList";
import { setShowSuccessSnackBar, setShowErrorSnackBar } from '../../app/slice/personalisationSlice';
import axios from "axios";
import { getFormatedPhoneNumber, pickContact } from '../../utils/phoneNumberUtil';
import "./NewChatMobile.css";
import { sendMessageApi } from '../../apis/chat/messageApi'
import GenericButton from "../../layouts/button/Button";
import { formatPhoneNumberIntl, getCountryCallingCode, parsePhoneNumber,isValidPhoneNumber } from "react-phone-number-input";
import { addNewContact, checkNumberExist, editContact, checkOptInOptOutStatus } from '../../apis/contacts/contactsApi'
import Paper from "@mui/material/Paper";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from 'react-redux';
import { setChatDetails, setRefreshChatList, setChatType } from "../../app/slice/chatSlice";
import LogoutModal from "../../components/logoutModal/LogoutModal";
import { getDeviceType } from '../../utils/deviceTypeUtil';
import MultiContactSelect from "../../components/contact/MultiContactSelect/MultiContactSelect";
import { getAOCStatus, getSRFQuantity } from "../../apis/srfApi/srfApi";
import { setAOCStatus, setPresQuantity, setRepQuantity } from "../../app/slice/srfSlice";
import { Keyboard } from '@capacitor/keyboard';
import PhoneNumberDropdown from "../../layouts/phoneNumberDropdown/PhoneNumberDropdown";
import { isValid } from "../../utils/CheckValidString";
import getErrorMessage from "../.././utils/getErrorCodeFromJsonFile";
import getOptinBadgeStatus from "../../utils/OptinBadge";
import ConfirmPopup from "../../components/ConfirmPopup/ConfirmPopup";


const BpIcon = styled("span")(({ theme }) => ({
  borderRadius: "50%",
  width: 16,
  height: 16,
  boxShadow:
    theme.palette.mode === "dark"
      ? "0 0 0 1px rgb(16 22 26 / 40%)"
      : "inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)",
  backgroundColor: theme.palette.mode === "dark" ? "#394b59" : "#f5f8fa",
  backgroundImage:
    theme.palette.mode === "dark"
      ? "linear-gradient(180deg,hsla(0,0%,100%,.05),hsla(0,0%,100%,0))"
      : "linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))",
  ".Mui-focusVisible &": {
    outline: "2px auto rgba(19,124,189,.6)",
    outlineOffset: 2,
  },
  "input:hover ~ &": {
    backgroundColor: theme.palette.mode === "dark" ? "#30404d" : "#ebf1f5",
  },
  "input:disabled ~ &": {
    boxShadow: "none",
    background:
      theme.palette.mode === "dark"
        ? "rgba(57,75,89,.5)"
        : "rgba(206,217,224,.5)",
  },
}));

let primcolor = document.documentElement.style.getPropertyValue('--primary-color');
const BpCheckedIcon = styled(BpIcon)({

  backgroundColor: primcolor ? document.documentElement.style.getPropertyValue('--primary-color') : '#734bd1',
  backgroundImage:
    "linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))",
  "&:before": {
    display: "block",
    width: 16,
    height: 16,
    backgroundImage: "radial-gradient(#fff,#fff 28%,transparent 32%)",
    content: '""',
  },
  "input:hover ~ &": {
    backgroundColor: primcolor ? document.documentElement.style.getPropertyValue('--primary-color') : '#734bd1',
  },
});

// Inspired by blueprintjs
function BpRadio(props: RadioProps) {
  return (
    <Radio
      sx={{
        "&:hover": {
          bgcolor: "transparent",
        },
      }}
      disableRipple
      color="default"
      checkedIcon={<BpCheckedIcon />}
      icon={<BpIcon />}
      {...props}
    />
  );
}

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "28%",
  borderRadius: "1rem",
  padding: ".5rem",
  bgcolor: "white",
  border: "none",
};


export default function NewChatMobile() {
  const dispatch = useDispatch()
  const { t } = useTranslation();
  const messageChannelType = useSelector((state: any) => state.authApi.EmployeeMsgorWhatsAppChannelType)
  const [othersName, setOthersName] = useState("");
  const [assoPrescName, setAssoPrescName] = useState("");
  const [othersPhone, setOthersPhone] = useState("");
  const [hiddenPresList, setHiddenPresList] = useState(true);
  const [assoPresc, setAssoPresc] = useState({ Id: "", Acct_Splty: "", Acct_Adr: "" });
  const [post, setPost] = useState([]);
  const [disabled, setDisabled] = useState(true);
  const [formType, setFormType] = useState(true);
  const [formTemplateVisible, setTemplateVisible] = useState(true);

  const [templateValue, setTemplateValue] = useState<any>({});
  const [campaignId, setCampaignId] = useState(0)
  const [templateId, setTemplateId] = useState(0);
  const [templateText, setTemplateText] = useState('')
  const [originalTemplateText, setOriginalTemplateText] = useState('')
  const [isSMS, setIsSms] = useState(true);// if true then whatsapp or else message

  const [pageSize, setPageSize] = useState(10);
  const [page, setPage] = useState(0);
  const [campaign, setCampaign] = useState([]);
  const [activeCampaign, setActiveCampagign] = useState(1000);//userId
  const [template, setTemplate] = useState<any>([])
  const [othersData, setOthersData] = useState([])
  const [othersDatahidden, setOthersDataHidden] = useState(true)
  const [selectedOthers, setSelecteedOthers] = useState<any>({})
  const debouncedValue = useDebounce<string>(othersName, 350);
  const [assets, setAssets] = useState([]);
  const [disableBrandInput, setDisableBrandInput] = useState(true)
  const [disableCampaignInput, setDisableCampaignInput] = useState(true)
  const [disableWaveInput, setDisableWaveInput] = useState(true)
  const [disableSMSInput, setDisableSMSInput] = useState(true)

  const [phoneError, setPhoneError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [refreshTemplate, setRefreshTemplate] = useState(true)
  const [associated, setAssociated] = useState<any>({})
  const [isForm, setIsForm] = useState(false)
  const [formQuestion, SetFromQuestion] = useState("");
  const [forms, setForms] = useState([])
  const [selectedForm, setSelectForm] = useState<any>({})
  const [optedOut, setOptedOut] = useState(false);

  const [formsValues, setFormValues] = useState<any>([])

  const refForm = useRef<any>(null);
  const [allCampaign, setAllCampaign] = useState<any>([]);
  const [allWaves, setAllWaves] = useState<any>([]);

  const [activeWave, setActiveWave] = useState<any>(null);
  const [activeProduct, setActiveProduct] = useState<any>(null);
  const [disableTemplate, setDisableTemplate] = useState<any>(true)
  const [modalHeight, setModalHeight] = useState(0);
  const [modalBodyHeight, setModalBodyHeight] = useState(0);
  const [mobileStyle, setMobileStyle] = useState(false);
  const [initialPhone, setInitialPhone] = useState<any>(true);
  const [showOnce, setShowOnce] = useState(false);
  const [overrideModal, setOverrideModal] = useState(false)
  const [formDataGlobal, setFormDataGlobal] = useState<any>(null)
  const [optinStatusFlag,setOptinStatusFlag]=useState("")
  const [address, setAddress] = useState("")
  const [speciality, setSpeciality] = useState("")

  // State for multi contact select
  const [multiContact, setMultiContact] = React.useState([]);
  const [open, setOpen] = React.useState(false);
  const [selectedContact, setSelectedContact] = React.useState('');
  const [repQuantity,setRepQuantityLocal]=useState<any>([])
  const [presQauntity,setPresQauantityLocal]=useState<any>([])
  const [aoc,setAoc]=useState<any>([])
  const [tempProductId,setTempProductId]=useState<any>(0)
  const [repQuantityError,setRepQuantityError]=useState(false);
  const [presQuantityError,setPresQuantityError]=useState(false);
  const [aocError,setAocError]=useState(false);
  const [discolor, setDiscolor] = useState(true);
  const [asscPresInputDisabled,setAsscPresInputDisabled]= useState(true);
  const [error,setError]=useState(false);
  const [isValidString,setIsValidString]=useState(false);
  const [errorData, setErrorData] = useState('');
  const [countries, setCountries] = useState<any>(
    config.REACT_APP_COUNTRY_LIST?.split(",")?.map((code: string) =>
      code.toLowerCase()
    )
  );
  const repId = useSelector((state: any) => state.authApi.Id);
  // const handleClickOpen = () => {
  //   setOpen(true);
  // };

  function handleClose(value: string) {
    setOpen(false);
    setSelectedContact(value);
    setOthersPhone(value);
  };
  const pageTitle = useSelector((state: any) => state.sideBar.title)
  const [isNumberLandline, setIsNumberLandline] = useState(false);
  const [othersLoading, setOthersLoading] = useState(false);
  const [overrideMessage, setOverrideMessage] = useState('');
  const [linkCharLimitError, setLinkCharLimitError] = useState(false);
  const [showBlockedContactConfirmPopup, setShowBlockedContactConfirmPopup] = useState<boolean>(false);
  const [isNumberBlocked, setIsNumberBlocked] = useState<boolean>(false);

  // const smapleFieldData=[
  //   {FieldId: 1063, FieldName: "Date", FieldType: "Date"},
  //   {FieldId: 1064, FieldName: "Time", FieldType: "Time"},
  //   {FieldId: 1065, FieldName: "Checkbox", FieldType: "Checkbox"},
  //   {FieldId: 1062, FieldName: "Additional Information", FieldType: "String"},

  // ]
  // const {
  //   data: post,
  //   isFetching,
  //   isSuccess,
  // } = useGetPrescribersQuery({
  //   page: 0,
  //   pageSize: 5,
  //   search: assoPrescName,
  // });
  // const pullTemplateData = (data) => {
  //    console.log(data.length); // LOGS DATA FROM CHILD (My name is Dean Winchester... &)
  //   // { ? : ''}
  //   if (data.length > 0) {
  //     setTemplateValue(true);
  //   } else {
  //     return null;
  //   }
  // };

//   const isValid = (inputString:string)=>{

//     let pattern = /^(?=.*[a-zA-Z].*[a-zA-Z].*[a-zA-Z])[a-zA-Z0-9\s]{1,97}[a-zA-Z0-9]?\s*$/;
//     let result = pattern.test(inputString);

    

//     return result;
// }
useEffect(() => {
  setCountryList();
  setErrorData("*"+t(getErrorMessage('NameError')))
},[]);
const setCountryList = () => {
let userList: Array<any> = JSON.parse(config.REACT_APP_TEST_USER_LIST);
    let countryTestUserCountryList:Array<any>= config.REACT_APP_TEST_USER_COUNTRY_LIST?.split(",")?.map((code: string) =>
    code?.toLowerCase()
  )
    userList?.forEach((userId) => {
      if (userId === repId) {
        setCountries(countryTestUserCountryList);
      }
    });
};
  useEffect(() => {
    window?.addEventListener('orientationchange', calcHeightWidth);
    return () => {
      window.removeEventListener('orientationchange', calcHeightWidth)
    }
  }, [])


  const falsifyShowOnce = () => {
    setShowOnce(false);
  }

  const handleOverrideMsg = (accType,accName) => {
    if(config.REACT_APP_PH_OVERRIDE_MSG){
      let msg = t(config.REACT_APP_PH_OVERRIDE_MSG);
        let type = accType === "N" ? "Others" : "Prescriber";
        let name = accName;
        if(type){
          msg = msg.replace("TYPE", type);
        }else{
          msg = msg.replace("TYPE -", "");
        }
        if(name){
          msg = msg.replace("NAME", name);
        }
        setOverrideMessage(msg);
    }
  } 

  useEffect(() => {

     if(config.REACT_APP_IS_CROSS_PLATFORM) {
   
     Keyboard.addListener('keyboardWillShow', info => {
     });
     Keyboard.addListener('keyboardDidShow', info => {
     calcHeightWidth();
     });
   
     Keyboard.addListener('keyboardWillHide', () => {
   
     });
     Keyboard.addListener('keyboardDidHide', () => {
   
     calcHeightWidth();
   
     console.log("---Keyboard height---");
   
     });
     }
   
     calcHeightWidth()
   
   window?.addEventListener("resize", (e) => {calcHeightWidth()})
   
}, []);


  useEffect(() => {
    document.documentElement.style.setProperty('--visibility', 'initial');
    document.getElementById("page-name")?.setAttribute('data-content', t(pageTitle));
    console.log(axios.CancelToken.source());
    getSRFQuantity((res:any)=>{
      console.log(res)
      if(res){
        setRepQuantityLocal(res.employeeQnt?res.employeeQnt:[]);
        setPresQauantityLocal(res.prescriberQnt?res.prescriberQnt:[]);
        dispatch(setRepQuantity(res.employeeQnt?res.employeeQnt:[]))
        dispatch(setPresQuantity(res.prescriberQnt?res.prescriberQnt:[]))
      }
    })
    // getAOCStatus((res:any)=>{
    //   if(res){
    //     setAoc(res)
    //     dispatch(setAOCStatus(res))
    //   }
    // })   /* Commenting this code for load test issue where it was calling without use */
    getTemplatesFromCampign();
    calcHeightWidth();
  }, []);
  const calcHeightWidth = () => {
    let doc: any = document
    let div: any = document.querySelector('#main')
    if (!div) return
    let divpaddingTop: any = parseInt(window.getComputedStyle(div, '').getPropertyValue('padding-top'));
    let divpaddingbottom: any = parseInt(window.getComputedStyle(div, '').getPropertyValue('padding-bottom'));
    let divpaddingleft: any = parseInt(window.getComputedStyle(div, '').getPropertyValue('padding-left'));
    let divpaddingright: any = parseInt(window.getComputedStyle(div, '').getPropertyValue('padding-right'));

    // console.log("gc modal toppad", divpaddingTop);
    // console.log("gc modal bottompad", divpaddingbottom);
    // console.log("gc modal leftpad", divpaddingleft);
    // console.log("gc modal rightpad", divpaddingright);
    // console.log("gc modal height", doc.getElementById('MobHeader').offsetHeight);
    // console.log("gc modal width", doc.getElementById('headerVal').offsetHeight);
   
    let device = getDeviceType()
    if (device == "mobile" || device == "tablet" || config.REACT_APP_IS_CROSS_PLATFORM) {
      setMobileStyle(true);
      setModalHeight(window.innerHeight - divpaddingTop - divpaddingbottom - doc.getElementById('MobHeader').offsetHeight - doc.getElementById('headerVal').offsetHeight - (2 * parseInt(window.getComputedStyle(doc.getElementsByClassName('modal-body')[0], '').getPropertyValue('padding'))));
      //  doc.getElementById('MobHeader').offsetHeight-doc.getElementById('headerVal').offsetHeight-doc.getElementById('footer').offsetHeight
      setModalBodyHeight(window.innerHeight - divpaddingTop - divpaddingbottom - doc.getElementById('MobHeader').offsetHeight - doc.getElementById('headerVal').offsetHeight - doc.getElementById('footer').offsetHeight - (2 * parseInt(window.getComputedStyle(doc.getElementsByClassName('modal-body')[0], '').getPropertyValue('padding'))));
    }

  }

  const getContactsData = (value) => {
    console.log(template)
    // useGetContactsQuery({
    //         page:this.state.page,
    //         pageSize:this.state.pageSize,
    //         search:this.state.search
    //       })
    // const DATATEMP = useGetContactsQuery({
    //       page:this.state.page,
    //       pageSize:this.state.pageSize,
    //       search:this.state.search
    //     })
    //     console.log(DATATEMP);
    
    if (value.length >= 2) {
      axios
        .get(
          config.REACT_APP_CONTACTS_API_BASE +
          "/contact?ContactType=A&Page=" +
          0 +
          "&PageSize=" + pageSize + "&Search=" +
          encodeURIComponent(value),
          {
            headers: {
              Authorization: "Bearer " + sessionStorage.getItem("login"),
            },
          }
        )
        .then((res) => {
          setOthersData(res.data.data.filter((x: any) => x.Is_Prescriber == "N"))
          setPost(res.data.data.filter((x: any) => x.Is_Prescriber == "Y"));
          setOthersLoading(false)
        });
    } else {
      setOthersDataHidden(true)
      setOthersData([])
      setPost([]);
      setHiddenPresList(true);
    }
  };
  const checkStopTyping = (e) => {
    var timeout = setTimeout(function () { }, 0);
    clearTimeout(timeout); /// clear timeout if user is typing

    timeout = setTimeout(
      function () {
        getContactsData(assoPrescName);
      },
      500 /// Time in milliseconds
    );
  };
  useEffect(() => {
    // Do fetch here...
    // Triggers when "debouncedValue" changes
    setOthersDataHidden(false)
    getContactsData(debouncedValue);


  }, [debouncedValue])
  const setPrescriber = (x: any) => {
    setAssoPrescName(x.AcctFull_Nm);
    setAssoPresc(x);
    setAddress(x.Acct_Adr)
    setSpeciality(x.Acct_Splty)
    setOthersPhone(getNumberFromItem(x) == "" ? `${config.REACT_APP_DEFAULT_COUNTRY_CODE}` : getNumberFromItem(x));
    setHiddenPresList(true);
    setDisabled(false);
    // brandInputdisable(true,(x.AcctPh_Nbr.length >0),true)
    brandInputdisable(x.AcctFull_Nm.length > 0, (x.AcctPh_Nbr.length > 0), true)
    
    console.log(x)
    setOptinStatusFlag(x.All_Ph_Cont_Flags)
    setOptinStatusFlag(x.All_Ph_Cont_Flags)
    setOptedOut(x.All_Ph_Cont_Flags === "N, N, N");
    if (x.AcctFull_Nm !== '' || x.AcctFull_Nm !== null || x.AcctFull_Nm !== undefined) {
      setDiscolor(false)
    }
  };


  const updateFormValue = (i, value) => {
    let maxLengthTextForLink = 780;
    if(value.length <= maxLengthTextForLink){
      let formVal = [...formsValues];
      let item = {
        ...formVal[i],
        Value: value
      }
      formVal[i] = item;
      setFormValues(formVal);
      updateTemplateText(formVal);
      setDisabled(false);
      setLinkCharLimitError(false);
    }
    else{
      setDisabled(true);
      setLinkCharLimitError(true);
    }
  }
  const updateTemplateText = (formData) => {
    var templateBody = originalTemplateText;
    // var templateBody ="Lets meet at [Time] on [Date]. [Checkbox]";
    formData.map((x: any, i: any) => {
      if ('Value' in x)//remove this condition
      {
        var replaceWord = "[" + x.FieldName + "]";
        templateBody = templateBody.replace(replaceWord, x.Value);
      }
    }
    );
    setTemplateText(templateBody);
  }

  const getFormStatus=(value:any)=>{
    let rep=repQuantity.filter(x=>x.product_id==tempProductId && x.prescriber_id==assoPresc.Id)
    let rq=rep.length>0?rep[0].quantity:0;
    let pres=presQauntity.filter(x=>x.product_id==tempProductId && x.prescriber_id==assoPresc.Id)
    let pq=pres.length>0?pres[0].quantity:0;
    let n=aoc.filter(x=>x.product_id==tempProductId && x.prescriber_id==assoPresc.Id && x.aoc_status =='pending')
    let nc=n.length>0?n[0].aoc_status:'unknown';
    
    if(value>rq && rq!=0){
      setRepQuantityError(true);
    }else{
      setRepQuantityError(false);
    }
    if(value>pq && pq!= 0){
      setPresQuantityError(true);
    }else{
      setPresQuantityError(false);
    }
    if(nc=='pending')
      setAocError(true)
    else
      setAocError(false);

    if((value>rq && rq!=0)||(value>pq && pq!= 0)|| nc=='pending')
    setDisabled(true)
    else
    setDisabled(false)

  }
 const getRepLimit=()=>{
  let rep=repQuantity.filter(x=>x.product_id==tempProductId && x.prescriber_id==assoPresc.Id)
    let rq=rep.length>0?rep[0].quantity:0;
    return rq;
 }  
 const getPresLimit=()=>{
  let rep=presQauntity.filter(x=>x.product_id==tempProductId && x.prescriber_id==assoPresc.Id)
  let rq=rep.length>0?rep[0].quantity:0;
  return rq;
 }

 const updateOtherStaffAssociation = async () => {
  try {
    if (!selectedOthers) return;
    let original = selectedOthers.Prescribers || [];
    if (original.findIndex((p) => p.Id == associated.Id) >= 0) return;
    let forDelete: any = original
      .filter((x: any) => {
        if (x.Association_Id) return x.Id;
      })
      .map((x) => x.Association_Id);
    let forAdd: any = [associated].map((x) => x.Id);
    if (forAdd.length > 0) {
      let assoPayload = {
        PresciberAccociatesPrescriberAccoc_Id: selectedOthers.Id,
        PresciberAccociatesPrescrber_Id: forAdd,
      };
      await axios.post(
        config.REACT_APP_CONTACTS_API_BASE + "/contact/associateassociation",
        assoPayload
      );
    }
    if (forDelete.length > 0) {
      let assoPayload = {
        Ids: forDelete,
      };
      await axios.post(
        config.REACT_APP_CONTACTS_API_BASE +
          "/contact/associateassociation/delete",
        assoPayload
      );
    }
  } catch (e) {
    console.log(e);
  }
};

  const sendMessageForPresOrOthers = (formData) => {
    
    if (formType) {
      //prescriber
      setLoading(true)
      if (getNumberFromItem(assoPresc) !== othersPhone || assoPresc.Acct_Splty !== speciality || assoPresc.Acct_Adr !== address) {
        let phn: any = parsePhoneNumber(othersPhone)
        let payload: any = {
          AcctPh_Nbr: phn.nationalNumber,
          IntlCallPfx_Nbr: phn.countryCallingCode,
          Id: assoPresc.Id,
          Acct_Splty: speciality ? speciality : "",
          Acct_Adr: address ? address : ""
        }
                checkNumberExist(payload.AcctPh_Nbr, (res: any) => {
          if (res && getNumberFromItem(assoPresc) !== othersPhone) {
            handleOverrideMsg(res.Is_Prescriber,res.AcctFull_Nm);
            setFormDataGlobal(formData)
            setOverrideModal(true);
          } else {
            editContact(payload, (res: any) => {
            })
            sendToPrescriber(formData)
          }
        })

      } else {
        sendToPrescriber(formData)
      }


    }
    else {
      //others
      let isValidStringEntered = isValid(othersName);

      let ContactName = othersName.replace(/\s+/g,' ').trim();

      if(ContactName && isValidStringEntered){
        setLoading(true)

          if (!selectedOthers.Id) {
            let phn: any = parsePhoneNumber(othersPhone);
            let payload = {
              AcctPh_Nbr: phn.nationalNumber,
              Prescriber_Id: [associated.Id],
              AcctFull_Nm: ContactName,
              IntlCallPfx_Nbr: phn.countryCallingCode,
              Acct_Splty: speciality,
              Acct_Adr: address
            }
            
            checkNumberExist(payload.AcctPh_Nbr, (res: any) => {
              if (res) {
                
                handleOverrideMsg(res.Is_Prescriber,res.AcctFull_Nm);
                setFormDataGlobal(formData);
                setOverrideModal(true);
              } else {
                
                addNewContact(payload, (res: any) => {
                  if (res) {
                    formData.append("recipient_id", res.Id);
                    formData.append("recipient_name", ContactName);
                    formData.append("role_id", '1');
                    formData.append('association_id', res.Prescribers.filter(x => x.Id == associated.Id)[0].Association_Id)
                    formData.append("physcian_id", associated.Id);
                    sendMessageApi(formData, (resp: any) => {
                      //console.log(res)
                      if (resp) {
                        dispatch(setChatType("ASSPRS"))
                        
                        dispatch(setChatDetails(
                          {
                            chatId: res.Id,
                            chatIsPrescriber: false,
                            showChat: true,
                            chatName: ContactName
                          }))
                        setLoading(false);
                        reSetFormInputs(true);

                        dispatch(setRefreshChatList(true))

                      }
                    })
                  }
                  else {

                  }
                })
              }
            })
          } else {
            let phn: any = parsePhoneNumber(othersPhone);
            console.log(phn)
            let payload = {
              AcctPh_Nbr: phn.nationalNumber,
              AcctFull_Nm: ContactName,
              Prescriber_Id: [associated.Id],
              Id: othersData.map((x: any) => x.Id),
              IntlCallPfx_Nbr: phn.countryCallingCode,
              Acct_Splty: speciality,
              Acct_Adr: address
            }
            formData.append("recipient_id", selectedOthers.Id);
            formData.append("recipient_name", ContactName);
            formData.append("role_id", '1');
            formData.append('association_id', selectedOthers?.Prescribers[0]?.Association_Id)
            formData.append("physcian_id", associated.Id);

            if (othersPhone != getNumberFromItem(selectedOthers) || selectedOthers.Acct_Splty !== speciality || selectedOthers.Acct_Adr !== address) {
                            checkNumberExist(payload.AcctPh_Nbr, (res: any) => {
                if (res && othersPhone != getNumberFromItem(selectedOthers)) {
                  handleOverrideMsg(res.Is_Prescriber,res.AcctFull_Nm);
                  setFormDataGlobal(formData);
                  setOverrideModal(true);
                } else {
                  addNewContact(payload, async (cont) => {
                    if (cont) {
                      if ((selectedOthers.Prescribers[0].Id || "") !== (associated?.Id || "")){
                        updateOtherStaffAssociation()
                      }
                      dispatch(setShowSuccessSnackBar(true));
                    }
                    else {
                      dispatch(setShowErrorSnackBar(true));
                    }
                  })
                  sendMessageApi(formData, (res) => {
                    //console.log(res)
                    dispatch(setChatType("ASSPRS"))
                    dispatch(setChatDetails(
                      {
                        chatId: selectedOthers.Id,
                        chatIsPrescriber: false,
                        showChat: true,
                        chatName: ContactName
                      }))
                    setLoading(false);
                    reSetFormInputs(true);

                    dispatch(setRefreshChatList(true))

                  })
                }
              })

            }
            else {
              if ((selectedOthers.Prescribers[0].Id || "") !== (associated?.Id || "")){
                updateOtherStaffAssociation()
              }
              sendMessageApi(formData, (res) => {
                //console.log(res)
                dispatch(setChatType("ASSPRS"))
                dispatch(setChatDetails(
                  {
                    chatId: selectedOthers.Id,
                    chatIsPrescriber: false,
                    showChat: true,
                    chatName: ContactName
                  }))
                setLoading(false);
                reSetFormInputs(true);

                dispatch(setRefreshChatList(true))

              })
            }

          }
    }
    else{
      setIsValidString(!isValidStringEntered);
      setSelecteedOthers({});
      setOthersPhone("");
      setAssoPrescName("");
      setAsscPresInputDisabled(true);
      setDiscolor(true);
      
      // reSetFormInputs(false)
      brandInputdisable(othersName.length > 0, othersPhone.length > 0, assoPrescName.length > 0)

      return;
    }

    }
    //  window.location.href = '/Chat'
    //  dispatch(setChatShowChat(true));
  }
  const sendToPrescriber = (formData: any) => {
    formData.append("role_id", '0');
    formData.append("recipient_id", assoPresc.Id);
    formData.append("recipient_name", assoPresc['AcctFull_Nm']);
    formData.append("physcian_id", assoPresc.Id);
    console.log("senddsad ::", templateText);
    sendMessageApi(formData, (res) => {
      //console.log(res)
      dispatch(setChatType("PRESCR"))
      dispatch(setChatDetails(
        {
          chatId: assoPresc.Id,
          chatIsPrescriber: true,
          showChat: true,
          chatName: assoPresc['AcctFull_Nm']
        }))

      setLoading(false);
      reSetFormInputs(true);
      dispatch(setRefreshChatList(true))

    })
  }
  async function addContact() {
    // const { data: post, isFetching, isSuccess } =await useAddContactQuery({
    //   AcctPh_Nbr:othersPhone,
    //   Prescriber_Id:x.Id,
    //   AcctFull_Nm:othersName,

    //     })

    //     if(isSuccess){
    //       alert('succee')
    //       ;
    //     }
    //     handleCloseParent()

    let payload = {
      AcctPh_Nbr: othersPhone,
      Prescriber_Id: assoPresc.Id as any,
      AcctFull_Nm: othersName,
      IsPrescriber: false,
    };
    console.log(payload);
    axios
      .post(
        config.REACT_APP_CONTACTS_API_BASE + "/contact",
        {
          AcctPh_Nbr: othersPhone,
          Prescriber_Id: assoPresc.Id as any,
          AcctFull_Nm: othersName,
          IsPrescriber: false,
        },
        {
          headers: {
            Authorization: "Bearer " + sessionStorage.getItem("login"),
          },
        }
      )
      .then((res) => {
        // handleCloseParent(true);
      })
      .catch((err) => {
        // handleCloseParent(false);
      });
  }
  const sendMessage = () => {
    // let data=messageData[0];
    // console.log(contactData);
    
    //setLoading(true);
    setShowBlockedContactConfirmPopup(false);
    let formData = new FormData();

    
    formData.append("recipient_phone", othersPhone);

    formData.append("Campaign_ID", '1000')//templateValue.campaginId.toString());


    formData.append('wave_id', templateValue.mainWaveId)//templateValue.waveId.toString())
    formData.append('product_id', templateValue.productId)//templateValue.productId.toString())
    if(formsValues?.length>0){
      formData.append("dynTempObj", JSON.stringify(formsValues.map(t=> t.Value)));
  }

    if (!isSMS) {
      formData.append("channel", "SMS");

    } else {
      formData.append("channel", "whatsa");
    }
    


    if (isForm) {
      let fdata: any = new FormData(refForm.current)
      let details: any = [];
      for (const [key, value] of fdata) {
        details.push({ "idOfFieldToAskFromEmp": JSON.parse(key), "valueOfFieldToAskFromEmp": value })
      }

      let formPay = {
        "formId": selectedForm.FormId,
        "fieldDetails": details,
        "recipientId": assoPresc.Id,
        "recipientType": formType ? "PRESCR" : 'ASSPRS'

      }
      console.log(JSON.stringify(formPay))
      axios.post(config.REACT_APP_FORMS_API_BASE + '/formRequest', formPay).then((res: any) => {
        console.log(res.data)
        let temptest = templateText.toString();
        if (temptest.indexOf('shorturl') >= 0)
          formData.append("template", templateText.toString().replace('[shorturl_1]', res.data.FormUrl));
        else
          formData.append("template", templateText + " " + res.data.FormUrl);
        formData.append('urls', res.data.FormUrl)
        sendMessageForPresOrOthers(formData);
        let qtPayload={
          quantityUsed:details[0].valueOfFieldToAskFromEmp,
          prescriberId:assoPresc.Id,
          productId:tempProductId
        }
        axios.post(config.REACT_APP_CAMPAIGN_API_BASE+'/srf/updateQauntity',qtPayload).then((res:any)=>{
          
        })
      })
        .catch(err => {
          setLoading(false)
        })
    }
    else {
      let temp = templateText.toString();
      if (!templateValue.FormId && templateValue.FieldDetails.length > 0) {
        templateValue.FieldDetails.map((x: any) => {
          temp = temp.replace('[' + x.FieldName + ']', '');
          return x;
        })
      }
      formData.append("template_id", templateId.toString());
      formData.append("template", temp.toString());
      sendMessageForPresOrOthers(formData);
    }




    //for prescriber


    // formData.append("recipient_name", data['Sender Name']);
    // formData.append("recipient_phone", data.recipient_phone);
    //  formData.append("physcian_id", data.physcian_id);
    //  formData.append("role_id", data.type);
    //  formData.append("template", data.template);
    //  formData.append("Campaign_ID", 1000);
    //  formData.append("template_id");
    //  formData.append('wave_id',data.)

    

    // formData.append("file", this.files);
    //  for (var i = 0; i < files.length; i++) {
    //    let file = files[i];
    //    formData.append("file", file);
    //  }


    // axios.post(config.REACT_APP_MESSAGE_API_BASE+"/message",formData,{
    //   axios.post("http://localhost:8000/api/message",formData,{
    //     params:{"Authorization":"Bearer "+sessionStorage.getItem('login')}
    // }).then((res)=>{
    //   console.log(res)
    //   setLoading(false);
    //   reSetFormInputs(true);
    //   handleCloseParent(true)
    // }).catch(res=>setLoading(false))
  }
  const getTemplatesFromCampign = () => {

    getCampaignsData({ page: 0, pageSize: 10 }, (res) => {
      if (res.length > 0) {

        let tempAssets: any = [];
        let campaigns: any = [];
        let waves: any = [];
        let products: any = [];



        res.map((x: any) => {

          campaigns.push(x);
          x.type = "Campaign";
          x.idName = ["type", "CampaignName", "CamapignId"];
          x.labelName = "CampaignName";
          x.childName = "WaveDetails";
          x.WaveDetails.map((y: any) => {
            waves.push(y);
            y.type = "Wave";
            y.idName = ["type", "WaveName", "WaveId"];
            y.labelName = "WaveName";
            y.childName = "ProductDetails";
            //y.CampaignWave_Nm=y.WaveDetails?y.WaveDetails.Wave_Nm:"";
            y.ProductDetails.map((z: any) => {
              z.waveId = y.WaveId;
              products.push(z)
              z.type = "Product";
              z.idName = ["type", "ProductName", "ProductId"];
              z.labelName = "ProductName";
              z.childName = "TemplateDetails";
              z.TemplateDetails = z.TemplateDetails.sort(function (a: any, b: any) { return a.SortOrder_Nbr - b.SortOrder_Nbr })
              z.TemplateDetails.map((p: any) => {
                p.type = "Template";
                p.productId = z.ProductId;
                p.mainWaveId = (p.AssetDetails.length == 0) ? p.CampaignWaveId : p.AssetDetails[0].CampaignWaveId
                if (p.FormId) {
                  p.TemplateName = p.FormName
                }
                p.idName = ["type", "TemplateName", "TemplateId"];
                p.labelName = "TemplateName";
                p.childName = "AssetDetails";
                tempAssets = tempAssets.concat(p.AssetDetails)
                p.AssetDetails.map((o: any) => {
                  o.mainItem = true
                  o.type = "Asset"
                  o.idName = ["type", "AssetFile_Nm", "AssetId"]
                  o.labelName = "AssetFile_Nm"
                  return o;
                })
                return p;

                //tempAssets.push(y.campaignWaveAsset);
              })
              return z;
            })
            return y;
          });
          return x;
        });
        
        setAllCampaign(campaigns)
        // setDisableBrandInput(true);
        let activeC: any = window.localStorage.getItem('activeCampaign')
        if (activeC) {
          let index = campaigns.findIndex(x => x.CamapignId == JSON.parse(activeC).CamapignId)
          if (index >= 0) {
            activeC = JSON.parse(activeC);
            setActiveCampagign(campaigns[index])
          }
          else {
            activeC = campaigns[0];
            setActiveCampagign(campaigns[0])
          }

        } else {
          activeC = campaigns[0];
          setActiveCampagign(campaigns[0])
        }


        
        let tempWaves = activeC.WaveDetails ? activeC.WaveDetails : [];
        setAllWaves(tempWaves)

        let activeW: any = window.localStorage.getItem('activeWave')
        
        if (activeW) {
          let index = tempWaves.findIndex(x => x.WaveId == JSON.parse(activeW).WaveId)
          if (index >= 0) {
            activeW = JSON.parse(activeW);
            setActiveWave(tempWaves[index])
          }
          else {
            activeW = tempWaves[0];
            setActiveWave(tempWaves[0])
          }

        } else {
          activeW = tempWaves[0];
          setActiveWave(tempWaves[0])
        }


        // let p:any=window.localStorage.getItem('activeWave')
        // setActiveWave(p?JSON.parse(p):null)
        let tempProducts = activeW.ProductDetails ? activeW.ProductDetails : []
        
        setCampaign(tempProducts)//campagins is used as products
        let activep: any = window.localStorage.getItem('activeProduct')

        if (activep) {
          let index = tempProducts.findIndex(x => x.ProductId == JSON.parse(activep).ProductId)
          if (index >= 0) {
            activep = JSON.parse(activep);
            setActiveProduct(tempProducts[index])
          }
          else {
            activep = tempProducts[0];
            setActiveProduct(tempProducts[0])
          }

        } else {
          activep = tempProducts[0];
          setActiveProduct(tempProducts[0])
        }

        setTempProductId(activep.ProductId);
        // let o=window.localStorage.getItem('activeProduct')
        // setActiveProduct(o?JSON.parse(o):null)
        let tempTemplates = activep.TemplateDetails ? activep.TemplateDetails : []
        setTemplate(tempTemplates);

        // getFormsData()
        // setTemplate(tempAssets);
        // setAlllProducts(products);
        // setActiveProducts(products)

        // setAlllWaves(waves);
        // setActiveWaves(waves);
        // setAssets(tempAssets);
      }
    }
    )


  };

  const disableWaveNTemplate = ()=>{

    setTimeout(() => {
      setSelecteedOthers({});
      setInitialPhone(true);
      setTemplateId(0);
      setAssoPrescName("");
      setAssoPresc({ Id: "", Acct_Splty: "", Acct_Adr: "" });
      setHiddenPresList(true);
      setDisabled(true);

      setOthersPhone("");
      setOthersPhone("9999999");
      setOthersPhone("");
      setDisableWaveInput(true)
      setDisableSMSInput(true)
      setActiveWave(null)
      setTemplate([])
      setTemplateValue({})
      setTemplateText("");

      setOriginalTemplateText("");
      setOthersName("");
      setDisableCampaignInput(true);

      setOptedOut(false);
      setAsscPresInputDisabled(true);
      setDiscolor(true);
      setFormValues([])
      setAssets([]);
      setAssociated({});

    }, 200);
   
  }

  const reSetFormInputs = (e) => {
    // console.log(e)
    setInitialPhone(true)
    setFormType(e)
    // if(e === true){
    // setOthersPhone("");
    // setTemplateText("")
    // setAssoPrescName("");
    // }else{
    setTemplateId(0)
    setAssoPrescName("");
    setAssoPresc({ Id: "", Acct_Splty: "", Acct_Adr: "" });
    setHiddenPresList(true);
    setDisabled(true);
    setOthersPhone( `${config.REACT_APP_DEFAULT_COUNTRY_CODE}`);
    setDisableBrandInput(true)
    setDisableCampaignInput(true)
    setDisableWaveInput(true)
    setDisableSMSInput(true)
    // setTemplate([]);
    setTemplateText("");
    setOriginalTemplateText("");
    setOthersName("");
    //  setAssoPrescName("");
    setAssets([]);
    setAssociated({})

    setActiveWave(null)
    setTemplate([])
    setTemplateValue({})

    setOptedOut(false);
    setAsscPresInputDisabled(true);
    setDiscolor(true);
    setAddress("")
    setSpeciality("")

    // }
  }
  // const getTemplatesFromCampign=(activeCampaign:any)=>{
  //   axios.get(config.REACT_APP_CAMPAIGN_API_BASE+"/campaign/"+activeCampaign+"?Page="+page+"&PageSize="+pageSize,{headers:{"Authorization":"Bearer "+sessionStorage.getItem('login')}}).then((res)=>{
  //     //setAssets(res.data.data);
  //     let tempAssets:any=[];
  //     console.log(res.data.data)
  //     setCampaign(res.data.data)
  //     res.data.data.map(x=>{
  //       x.refCampaignWaves.map((y:any)=>{
  //        tempAssets.push(y.campaignWaveTemplate);
  //        return y;
  //       });
  //       return x;
  //     })
  //     setTemplateId(tempAssets[0].id)
  //     setTemplateText(tempAssets[0].templateVal)
  //     setTemplate(tempAssets);
  //   })
  // }

  const setPrescriberName = (e) => {
    // if(e.length > 0){
    //  setDisabled(false)
    // }
    // else setDisabled(true)
    if (assoPrescName.length <= 0) {
      setDisabled(true)
    }
    else {
      setDisabled(false)
    }
  }
  const brandInputdisable = (name, phone, asso_pres) => {
    
    // console.log(assoPrescName === "" && othersPhone === "", assoPrescName, othersPhone)
    if (formType || !formType) {
      if (name && phone && asso_pres) {
        
        setDisableBrandInput(false)
        setDisableCampaignInput(false)
        setDisableWaveInput(false)
        setDisableSMSInput(false)
      } else {
        
        setDisableBrandInput(true)
        setDisableCampaignInput(true)
        setDisableWaveInput(true)
        setDisableSMSInput(true)
      }
    }
  

  }
  const getNumberFromItem = (item) => {
    
    if (item.AcctPh_Nbr) {
      if (item.AcctPh_Nbr.indexOf("+") >= 0) {
        return item.AcctPh_Nbr
      } else
        return '+' + item.IntlCallPfx_Nbr + item.AcctPh_Nbr
    }
    else return ""
  }
  const setPhoneNumber = (e) => {
    
    setInitialPhone(false)

    setError(!isValidPhoneNumber(e))

    if (e) {
      setOthersPhone(e);
      brandInputdisable((assoPrescName.length > 0), (e.length > 0), (assoPrescName.length > 0));
    } else {
      setOthersPhone("");
      brandInputdisable((assoPrescName.length > 0), false, (assoPrescName.length > 0));
    }
    if(e.length>0){
      setAsscPresInputDisabled(false)
    }
    else{
      setAsscPresInputDisabled(true)
    }
  }
  const selectbrand = (e) => {
    
    console.log('inside brand')
    window.localStorage.setItem('activeProduct', JSON.stringify(e))
    setTemplate(e.TemplateDetails ? e.TemplateDetails : [])
    setDisableTemplate(false)
    // if(e.TemplateDetails.length==1){
    //   setDisableTemplate(false)
    // }
    // else{
    //   setDisableTemplate(true)
    // }
    setActiveProduct(e);
    setTemplateText("");
    setTempProductId(e.ProductId);
    setOriginalTemplateText("");
    setTemplateId(0)
    setAssets([]);
    setIsForm(false)
    setDisableSMSInput(false);
    // if(e)
    // {
    //   setTemplateVisible(true);
    // }
    //  getFormsData(1001)
  }
  const selectWave = (e) => {
    // setDisableTemplate(true)
    window.localStorage.setItem('activeWave', JSON.stringify(e))
    //setActiveWave(e);
    setActiveWave(e)
    setActiveProduct(null)

    setCampaign(e.ProductDetails ? e.ProductDetails : [])
    if (e.ProductDetails.length == 1) {
      selectbrand(e.ProductDetails[0])
    }
    setTemplateText("");
    setOriginalTemplateText("");
    setTemplateId(0)
    setAssets([]);
    setIsForm(false)
    if (disableWaveInput == false) {
      if (allWaves.length == 1) {
        setDisableSMSInput(false);
      }
      else {
        setDisableBrandInput(false);
        setDisableSMSInput(true);
      }

    }
    else {
      setDisableBrandInput(true);
      setDisableSMSInput(false);
    }

  }
  const selectCampaign = (e) => {
    setDisableTemplate(true)
    window.localStorage.setItem('activeCampaign', JSON.stringify(e))
    //setActiveCampagign(e)
    setActiveWave(null)
    setActiveProduct(null)
    setAllWaves(e.WaveDetails ? e.WaveDetails : [])
    if (e.WaveDetails.length == 1) {
      selectWave(e.WaveDetails[0])
    }
    setTemplateText("");
    setOriginalTemplateText("");
    if (disableCampaignInput == false) {
      setDisableWaveInput(false);
      setDisableSMSInput(true);
      setDisableBrandInput(true);
    }
    else {
      setDisableWaveInput(true);
      setDisableSMSInput(false);
      setDisableBrandInput(false);
    }
    setTemplateId(0)
    setAssets([]);
    setIsForm(false)
  }

  const setOthersFromList = (x) => {
    if(x.Is_Prescriber=="N"){
        let RecType= false
        contactDetails({ Id: x.Id, Is_Prescriber: RecType }, (res) => {
      
      
      setTimeout(() => {
        setDisableWaveInput(false)
        setDiscolor(false);
        setAsscPresInputDisabled(false)
      }, 200);
      

      if (res) {
        console.log("jjjjjjjjjjjjjj", res)
        setShowOnce(false);
        console.log("lllllll", x)
        setDiscolor(false);
        setOptinStatusFlag(res.All_Ph_Cont_Flags)
        setOptedOut(res.All_Ph_Cont_Flags === "N, N, N");
        setSelecteedOthers(res);
        setOthersName(res.AcctFull_Nm);
        setAddress(res.Acct_Adr)
        setSpeciality(res.Acct_Splty)
        //setOthersPhone(x.AcctPh_Nbr);
        setOthersDataHidden(true);
        if (res.Prescribers) {
          
          if (res.Prescribers.length === 1) {
    
            setAssociated(res.Prescribers[0]);
            setPrescriberName(res.Prescribers[0].AcctFull_Nm);
            setAssoPrescName(res.Prescribers[0].AcctFull_Nm);
            setDisableTemplate(res.AcctPh_Nbr.length <= 0)
            brandInputdisable(
              true,
              res.AcctPh_Nbr.length > 0,
              true
            );
            setOthersPhone(getNumberFromItem(x) == "" ? "+1" : getNumberFromItem(x));
          }
          else {
            brandInputdisable(true, (res.AcctPh_Nbr.length > 0),false)
          }
        }
      }
      // setLoading(false);

  })} else {
    setOthersPhone(getNumberFromItem(x));
  }

   


  }
  const getHeight = () => {
    let viewportHeight = window.innerHeight;
    let doc: any = document;
    if (doc.getElementsByClassName('new_chat_body')[0])
      return (viewportHeight - doc.getElementsByClassName('new_chat_body')[0].offsetTop - 50);
    else return "100%"
  }
  const checkDisabled = (name, phone) => {
    // && camp && wave && brand && temp
    console.log("home page disable", !(name && phone && !disableCampaignInput && !disableBrandInput && !disableWaveInput && !disableTemplate));
    if (name && phone && !disableCampaignInput && !disableBrandInput && !disableWaveInput && !disableTemplate) {
      setDisabled(false);
    }
    else setDisabled(true);
  }
  const overrideFunction = (e) => {
    let formData: any = formDataGlobal;
    if (e) {
      if (formType) {
        let phn: any = parsePhoneNumber(othersPhone)
        let payload: any = {
          AcctPh_Nbr: phn.nationalNumber,
          IntlCallPfx_Nbr: phn.countryCallingCode,
          Id: assoPresc.Id,
          Acct_Splty: speciality ? speciality : "",
          Acct_Adr: address ? address : ""
        }
        editContact(payload, (res: any) => { })
        sendToPrescriber(formData)
      }
      else {
        if (!selectedOthers.Id) {
          let phn: any = parsePhoneNumber(othersPhone);
          let payload = {
            AcctPh_Nbr: phn.nationalNumber,
            Prescriber_Id: [associated.Id],
            AcctFull_Nm: othersName,
            IntlCallPfx_Nbr: phn.countryCallingCode,
            Acct_Splty: speciality,
            Acct_Adr: address
          }

          addNewContact(payload, (res: any) => {
            if (res) {
              formData.append("recipient_id", res.Id);
              formData.append("recipient_name", othersName);
              formData.append("role_id", '1');
              formData.append('association_id', res.Prescribers.filter(x => x.Id == associated.Id)[0].Association_Id)
              formData.append("physcian_id", associated.Id);
              sendMessageApi(formData, (resp: any) => {
                //console.log(res)
                if (resp) {
                  dispatch(setChatType("ASSPRS"))
                  
                  dispatch(setChatDetails(
                    {
                      chatId: res.Id,
                      chatIsPrescriber: false,
                      showChat: true,
                      chatName: othersName
                    }))
                  setLoading(false);
                  reSetFormInputs(true);

                  dispatch(setRefreshChatList(true))

                }
              })
            }
            else {

            }
          })
        } else {
          let phn: any = parsePhoneNumber(othersPhone);
          console.log(phn)
          let payload = {
            AcctPh_Nbr: phn.nationalNumber,
            AcctFull_Nm: othersName,
            Prescriber_Id: [associated.Id],
            Id: othersData.map((x: any) => x.Id),
            IntlCallPfx_Nbr: phn.countryCallingCode,
            Acct_Splty: speciality,
            Acct_Adr: address
          }
          addNewContact(payload, async (cont) => {
            if (cont) {
              if ((selectedOthers.Prescribers[0].Id || "") !== (associated?.Id || "")){
                updateOtherStaffAssociation()
              }
              dispatch(setShowSuccessSnackBar(true));
            }
            else {
              dispatch(setShowErrorSnackBar(true));
            }
          })
          sendMessageApi(formData, (res) => {
            //console.log(res)
            dispatch(setChatType("ASSPRS"))
            dispatch(setChatDetails(
              {
                chatId: selectedOthers.Id,
                chatIsPrescriber: false,
                showChat: true,
                chatName: othersName
              }))
            setLoading(false);
            reSetFormInputs(true);

            dispatch(setRefreshChatList(true))

          })

        }
      }
    }
    else {
      setLoading(false)
      setDisabled(false)
    }
  }



  const pickcontact = () => {
    if (config.REACT_APP_IS_CROSS_PLATFORM) {
      pickContact().then((resp: any) => {
        if (resp.contact.phones.length > 1) {
          // setOthersName(resp.contact.name.display)
          setMultiContact(resp.contact)
          setOpen(true);
        } else {
          //setOthersName(resp.contact.name.display)
          setOthersPhone(resp.contact.phones[0].number.split("-").join(" "))
        }
      });
    }
  }


  
  const setNumberLandline=(e)=> {
    console.log(e)
    setDisabled(e)
    setDisableTemplate(assoPrescName.length > 0 && e)
    brandInputdisable((assoPrescName.length > 0),!e,assoPrescName.length > 0)
    setIsNumberLandline(e)
  }

  const filterTemplate = (temp) => {
    const optinFlagColor = getOptinBadgeStatus(optinStatusFlag);
    if (temp?.length) {
      if (["M", "Y", "R"].includes(optinFlagColor)) {
        return temp.filter((t) => t.Category_Cd === "FTR");
      }
      else if(["G"].includes(optinFlagColor)) {
        return temp.filter((t) => t.Category_Cd != "FTR");
      }
    }
    return [];
  };

  const filteredTemplates = useMemo(()=> filterTemplate(template), [template, optinStatusFlag])

  useEffect(() => {
    if (othersPhone && isValidPhoneNumber(othersPhone)) {
      const phone: any = parsePhoneNumber(othersPhone);
            checkNumberExist(phone?.nationalNumber, (res: any) => {
                if (res) {
          setOptinStatusFlag(res.All_Ph_Cont_Flags);
        } else {
          checkOptInOptOutStatus(phone?.nationalNumber, (res: any) => {
            if(res){
              setOptinStatusFlag(res[0].OptInOutAll_Flg);
            }
            else{
              setOptinStatusFlag("");
            }
          });
        }
        setTemplateText("");
        setAssets([]);
        setFormValues([])
      });
    }
  }, [othersPhone]);

  return (
    <div className="new-chat-wrapper" style={mobileStyle ? { height: modalHeight } : {}}>
      <div className="modal-body new_chat_body" style={mobileStyle ? { height: modalBodyHeight } : {}}>
        <form onSubmit={(e) => { 
          e.preventDefault();
          if(isNumberBlocked) {
            setShowBlockedContactConfirmPopup(true);
          } else {
            sendMessage();
          }
          }}>
          <div className="selecttype-radio-btns">
            <FormControl className="width100per">
              <div className="newChatModalBody">
                <FormLabel id="demo-customized-radios">{t('Select Type')}</FormLabel>
                {
                  messageChannelType === true && config.REACT_APP_SHOW_CHANNEL_TYPE_TOGGLE_BTN ==="Y" ?
                    <div className='headerright' >
                      <label className="autopilot-switch font-sm me-3">
                        <input
                          checked={isSMS}
                          onChange={(e) => { setIsSms(e.target.checked) }}
                          type="checkbox"
                          className="font-sm"
                          id="msg-wtsappChechedId"
                        />
                        <span id="msg-wtsapptoggle" className="b-slider b-round"></span>
                      </label>
                    </div> : null}
              </div>
              <RadioGroup
                row
                defaultValue="prescriber"
                aria-labelledby="demo-customized-radios"
                name="customized-radios"
                onClick={(e: any) => {
                  e.target.value === "prescriber"
                    ? reSetFormInputs(true)
                    : reSetFormInputs(false);
                }}
              >
                <FormControlLabel
                  value="prescriber"
                  control={<BpRadio />}
                  label={t('Prescriber')}
                />
                {config.REACT_APP_OTHER_STAFF_DISABLED !=="N" && <FormControlLabel
                  value="others"
                  control={<BpRadio />}
                  label={t('Others')}
                />}
              </RadioGroup>
            </FormControl>
          </div>

          <div className="form-fields-div new-chat-prescriber">
            {formType === true ? (
              // <TextField
              //   className="roundborder"
              //   onChange={(e) => {
              //     setOthersName(e.target.value);
              //   }}
              //   value={othersName}
              //   label="Prescriber Name"
              //   placeholder="Please enter the prescriber name"
              //   color="secondary"
              //   focused
              // />
              <AssociatedPresList
                clearDataOnSelect={false}
                placeholder={t('Search for a Prescriber')}
                label={t('Prescriber Name')+'*'}
                styleClass="inputEnable"
                onChangeInputValue={
                  (e: any) => {
                    setPrescriberName(e);
                    setDisabled(true);
                    //checkDisabled(e.length != 0, !phoneError);
                    if(e.length == 0){
                      disableWaveNTemplate();
                    }
                  }}
                  onInputValue={(e) => {
                  }}
                setAssoPresc={
                  (e: any) => {
                    setPrescriber(e);
                    // setDisabled(false);
                    
                    checkDisabled(e.AcctFull_Nm.length > 0, !phoneError);
                  }}
              />
            ) : (
              <div className="NewChatModalOthersName">


                <TextField
                  className="roundborder inputEnable"
                  onChange={(e) => {
                    setShowOnce(true);
                    setIsValidString(false);

                    const result = e.target.value.replace(/[^A-Za-z0-9 _.]/gi, '');


                    if (result.length == 0) {
                      setSelecteedOthers({});
                      setOthersPhone("");
                      setAssoPrescName("");
                      setAsscPresInputDisabled(true);
                      setDiscolor(true);
                      
                      // reSetFormInputs(false)
                      //brandInputdisable(othersName.length > 0, othersPhone.length > 0, assoPrescName.length > 0)
                      disableWaveNTemplate();
                      setOthersLoading(false)

                      setOthersName(result);

                    }
                    else if (result.length > 0  && result) {

                              if(result.length == 1)
                              {
                                setDiscolor(true);
                                setAsscPresInputDisabled(true)
                              }else{
                                if(result.trim().length === 0){
                                  setDiscolor(true);
                                  setAsscPresInputDisabled(true)
                                }
                                else{
                                  setDiscolor(false);
                                  setAsscPresInputDisabled(false)
                                }
                                
                              }
                      //brandInputdisable(othersName.length > 0, othersPhone.length > 0, assoPrescName.length > 0)
                      setOthersName(result);

                    }
                    if (result.length > 1) {
                      setOthersLoading(true)
                    }
                    checkDisabled(e.target.value.length >= 0, !phoneError);
                  }}
                  value={othersName}
                  label="Recipient Name*"
                  placeholder={t("Enter the recipient's name")}
                  color="secondary"
                  focused
                  inputProps={{ maxLength: config.REACT_APP_CONTACT_NAME_MAXLENGTH }}
                  InputProps={
                    othersLoading?{
                    endAdornment: <InputAdornment position="end"><CircularProgress  color={'inherit'} size={'1rem'}/></InputAdornment>,
                  }:undefined}
                // disabled
                />
    
                  {
                     isValidString && <p style={{color:'red',fontSize:'12px',margin:'0'}}>{errorData}</p>
                  }

                {showOnce && othersData.length > 0 ?
                  <Paper hidden={othersDatahidden}>


                    <div id="presList" >

                      {othersData.length > 0 && (
                        othersData.map((x: any) => <div key={x.Id} onClick={() => { setOthersFromList(x) }}>{x.AcctFull_Nm}</div>)
                      )
                      }
                    </div>
                  </Paper> : null}
                {/* <div className="othesNameDiv" hidden={othersDatahidden}>
                    <ul>
                      {
                        othersData.map((x:any)=>(
                          <li key={x.Id} onClick={()=>{
                            setOthersFromList(x);
                            
                          }}>{x.AcctFull_Nm}</li>
                        ))
                      }
                      
                    </ul>
                  </div> */}
              </div>

            )}
            {/* {
              optedOut ? <div className="optedOutModalText">
                {t("Recipient Opted Out")}
              </div> : null
            } */}
             <div className="addContactWrapper">

            {/* <CountryDropdown 
            errorFunc={(e) => {
              console.log(e);
              setDisabled(e);
              // checkDisabled(othersName.length>0||assoPrescName.length>0,!e);
              setPhoneError(e);
              
              if (e) {
                setDisableTemplate(assoPrescName.length > 0 && true)
                brandInputdisable((assoPrescName.length > 0), false, assoPrescName.length > 0)
              }
              else {
                
                if (othersName.length <= 0 || assoPrescName.length <= 0) {
                  setDisabled(true);
                }
                setDisableTemplate(assoPrescName.length > 0 && false)
              }
              checkDisabled(othersName.length > 0 || assoPrescName.length > 0, !e);
            }}
            
            disableColored={disableBrandInput} 
            falsifyShowOnce={falsifyShowOnce} 
            valueNumber={othersPhone} 
            onChangeNumber={(e: any) => { 
              setPhoneNumber(e); 
            }} 
            numberLandline={(e: any) => {
              setNumberLandline(e)
            }} 
            /> */}
            
            <PhoneNumberDropdown 
            initiall={(e)=>{}}
            errorFunc={(e) => {
              console.log(e);
              setDisabled(e);
              // checkDisabled(othersName.length>0||assoPrescName.length>0,!e);
              setPhoneError(e);
              
              if (e) {
                setDisableTemplate(assoPrescName.length > 0 && true)
                console.log(disableTemplate)
                brandInputdisable((assoPrescName.length > 0), false, assoPrescName.length > 0)
              }
              else {
                
                if (othersName.length <= 0 || assoPrescName.length <= 0) {
                  setDisabled(true);
                }
                setDisableTemplate(assoPrescName.length > 0 && false)
              }
              checkDisabled(othersName.length > 0 || assoPrescName.length > 0, !e);
            }} 
            countryList={countries}
            disableColored={discolor} 
            falsifyShowOnce={falsifyShowOnce} 
            valueNumber={othersPhone} 
            onChangeNumber={(e: any) => { 
              setPhoneNumber(e); 
            }} 
            numberLandline={(e: any) => {
              setNumberLandline(e)
            }} 
            onBlockedContactStatusChange={(e: boolean) => {
              setIsNumberBlocked(e);
            }}
            />

            

            {config?.REACT_APP_IS_CROSS_PLATFORM && config?.REACT_APP_SHOULD_SHOW_CONTACT_PICKUP === 'Y' && othersPhone.length === 0 ? <button className="create-btn" onClick={pickcontact}>Add</button> : null}
              {/* multi contact select component */}
              {
                multiContact !== undefined && multiContact !== null && Object.keys(multiContact).length > 0 ?
                  <MultiContactSelect
                    selectedValue={selectedContact}
                    open={open}
                    onClose={handleClose}
                    multiContact={multiContact}
                  /> : null
              }</div>

                    <TextField
                      label={t("Address")}
                      className="roundborder inputDisable"
                        onChange={(e) => {
                          setAddress(e.target.value);
                        }}
                        focused
                        disabled
                        value={address || " "}
                        placeholder={t("Please enter address")}
                        color="secondary"
                        inputProps={{ maxLength: 200 }}
                      />

          
                      <TextField
                      label={t("Speciality")}
                      className="roundborder inputDisable"
                        onChange={(e) => {
                          setSpeciality(e.target.value);
                        }}
                        focused
                        disabled
                        value={speciality||" "}
                        placeholder={t("Please enter speciality")}
                        color="secondary"
                        
                    aria-expanded="true"
                        inputProps={{ maxLength: 50 }}
                      />

            {/* <TextField
                  label="Phone Number"
                  onChange={(e) => {
                    setOthersPhone(e.target.value);
                  }}
                  value={othersPhone}
                  placeholder="Please enter the phone number"
                  color="secondary"
                  focused
                /> */}
            {/* Associate perscriber */}
            {formType === false ? (
              <div className="asso-prescriber-div createContactAssociate">
                <div>
                  {
                    selectedOthers.Id ?
                      <div>
                        {selectedOthers.Prescribers.length > 1 ?
                          <DropdownInupt disableColored={false} keepPlaceHolderAtStart={selectedOthers.Prescribers.length > 1} placeHolder={t("Select a Prescriber")} label={t("Associated Prescriber")+'*'} data={selectedOthers.Prescribers} id="" name="AcctFull_Nm" func={(e) => { setAssoPrescName(e.AcctFull_Nm); setAssociated(e); brandInputdisable(othersName.length > 0, othersPhone.length > 0, true); setDisableTemplate(false); (e ? setDisabled(false) : setDisabled(true)) }} />
                          :
                          <div>
                            <AssociatedPresList falsifyShowOnce={falsifyShowOnce} SelectedValue={selectedOthers.Prescribers[0].AcctFull_Nm} clearDataOnSelect={false} placeholder={t("Search for an Associated Prescriber")} label={t("Associated Prescriber")+'*'} styleClass="inputEnable" onChangeInputValue={(e: any) => { setPrescriberName(e); if (e == '') { setDisabled(true) } }} setAssoPresc={(e: any) => { brandInputdisable(othersName.length > 0, othersPhone.length > 0, true); setAssoPrescName(e.AcctFull_Nm); setAssociated(e); setDisableTemplate(false); (e ? setDisabled(false) : setDisabled(true)) }} 
                            disable={asscPresInputDisabled}
                            />
                            {
                              // optedOut?<div>
                              //   {t("Recipient Opted Out")}
                              // </div>:null
                            }
                          </div>
                        }
                      </div>
                      :
                      <div>
                        <AssociatedPresList
                          // clearDataOnSelect={true}
                          falsifyShowOnce={falsifyShowOnce}
                          placeholder={t("Search for an Associated Prescriber")}
                          label={t("Associated Prescriber")+'*'}
                          styleClass="inputEnable"
                          onChangeInputValue={
                            (e: any) => { 
                              setPrescriberName(e);
                              setDisabled(true);
                            }}
                          setAssoPresc={(e: any) => {
                            // if(e)setDisabled(false);

                            checkDisabled(othersName.length > 0 || e.length > 0, !phoneError);
                            brandInputdisable(othersName.length > 0, othersPhone.length > 0, true);
                            setAssoPrescName(e.AcctFull_Nm);
                            setAssociated(e);
                            setDisableTemplate(false);
                            if (othersName.length <= 0) { setDisabled(true) }
                          }} 
                          disable={asscPresInputDisabled}
                          />
                        {/*{
                            optedOut?<div>
                              {t("Recipient Opted Out")}
                            </div>:null
                          } */}
                      </div>
                  }
                </div>



              </div>
            ) : null}
            {
              allCampaign.length > 1 ?
                <div>
                  <DropdownInupt activeValue={activeCampaign} disableColored={disableCampaignInput} keepPlaceHolderAtStart={false} placeHolder={t("Select a campaign")} label={t("Campaigns")+"*"} data={allCampaign} id="" name="CampaignName" func={(e) => { selectCampaign(e); setDisableSMSInput(false) }} />
                </div>
                : null
            }
            {
              allWaves.length > 1 ?
                <div>
                  <DropdownInupt activeValue={activeWave} disableColored={disableWaveInput} keepPlaceHolderAtStart={allWaves.length > 1} placeHolder={t("Select a wave")} label="Waves*" data={allWaves} id="" name="WaveName" func={(e) => { selectWave(e); setDisableSMSInput(false); }} />
                </div>
                : null
            }
            {
              campaign.length > 1 ?
                <div>
                  <DropdownInupt activeValue={activeProduct} disableColored={disableBrandInput} keepPlaceHolderAtStart={campaign.length > 1} placeHolder={t("Select a brand")} label="Brand*" data={campaign} id="" name="ProductName" func={(e) => { selectbrand(e); setDisableSMSInput(false); }} />
                </div> : null
            }


            <div>
              <DropdownInupt
                notdisabledAfter={true}
                //refreshData={refreshTemplate}
                label={t("SMS Template")+"*"}
                data={filteredTemplates}
                keepPlaceHolderAtStart={true}
                placeHolder={t("Select a Template")}
                name="TemplateName"
                disableColored={disableSMSInput}
                openTemplates={()=>{getTemplatesFromCampign()}}
                func={(e) => {
                  setLinkCharLimitError(false);
                  setFormValues([])
                  setIsForm(e.FormId !== null)
                  setTemplateText(e.TemplateBody); setOriginalTemplateText(e.TemplateBody); setTemplateValue(e); 
                  setTemplateId(e.TemplateId);
                  setDisabled(false);
                  if (!formType && (assoPrescName.length <= 0 || othersName.length <= 0 || othersPhone.length <= 0)) {
                    setDisabled(true)
                  }
                  setAssets(e.AssetDetails ? e.AssetDetails : []); setSelectForm(e);
                  
                  setTimeout(()=> {
                    setFormValues(e.FieldDetails);
                  }, 200)
                  // setFormValues(smapleFieldData)
                }}
              />
              {templateText.length > 0 ?
                <div className="newchat-floating-form" style={{ display: "block" }}>
                  <textarea name="sms-textarea" id="newchat_sms" value={templateText} />
                  <p>
                    <em> {t("Characters")} : {templateText.length}</em>
                  </p>
                </div> : null}


              {
                isForm ?

                  <div className="template_body_form">
                    <form ref={refForm}>
                      {
                        formsValues.map((x: any, i: any) => (
                          <>
                            <p className="para">{x.FieldName} max( {getRepLimit()} )</p>
                            <textarea style={{height:'1rem'}} name={x.FieldId} id={x.FieldId} onChange={(e)=>{
                          // quantity.map(q=>{
                          //   if(q.productid==='1000' && e.target.value>q.quantity)
                          //   setDisabled(true)
                          // })
                          getFormStatus(e.target.value);
                        }}  ></textarea>
                          </>
                        ))
                      }
                       {aocError?
                    <span style={{color:'red',fontSize:'1rem'}}>
                      {t('Previous AOC is pending.')}
                    </span>:null}
                    {
                      repQuantityError?<h6 style={{color:'red',fontSize:'1rem'}}>Your available limit is {getRepLimit()}</h6>:null
                    }
                    {
                      presQuantityError?<h6 style={{color:'red',fontSize:'1rem'}}>Prescriber's available limit is {getPresLimit()}</h6>:null
                    }
                    </form>
                  </div>

                  : null
              }
              {formsValues.length > 0 && !isForm &&
                <div className="template_body_form">

                  {
                    formsValues.map((x: any, i: any) => (
                      <>
                        {x.FieldType.toLowerCase() === "checkbox" &&
                          <div className="checkBoxField">
                            <input required={x.IsRequired ? x.IsRequired == "Y" : false} type="checkbox" id={x.FieldId} name={x.FieldId} onChange={(e) => { updateFormValue(i, e.target.checked) }} />
                            <label htmlFor={x.FieldId} className="checkboxLabel">{x.FieldName}</label>
                          </div>
                        }
                        {(x.FieldType.toLowerCase() === "string" ||
                         x.FieldType.toLowerCase() === "link") &&
                          <div className="meeting-textfield">
                            <TextField
                              required={x.IsRequired ? x.IsRequired == "Y" : false}
                              name={x.FieldId} id={x.FieldId}
                              className="roundborder inputEnable"
                              style={{ width: "100%" }}
                              label={t(x.FieldName)}
                              onChange={(e) => { updateFormValue(i, e.target.value) }}
                              color="secondary"
                              focused
                            />
                          </div>}
                        {x.FieldType.toLowerCase() === "date" &&
                          <TextField
                            name={x.FieldId} id={x.FieldId}
                            required={x.IsRequired ? x.IsRequired == "Y" : false}
                            className="Datepicker"
                            label={t(x.FieldName)}
                            type="date"
                            placeholder="MM/DD/YYYY"
                            sx={{ width: 220 }}
                            inputProps={{
                              style: {
                                height: '84',
                                padding: '1rem',
                                color: 'grey',
                              },
                              min: new Date().toISOString().split("T")[0]

                            }}
                            onChange={(e) => {
                              let s_date = moment(e.target.value).format("MM-DD-YYYY");
                              console.log("s_date", s_date)
                              updateFormValue(i, s_date)
                            }}
                            InputLabelProps={{
                              shrink: true,
                            }} focused />
                        }
                        {x.FieldType.toLowerCase() === "time" &&
                          <TextField
                            required={x.IsRequired ? x.IsRequired == "Y" : false}
                            className="Timepicker"
                            name={x.FieldId} id={x.FieldId}
                            label={t(x.FieldName)}
                            type="time"
                            placeholder="--:--"
                            InputLabelProps={{
                              shrink: true,
                            }}
                            inputProps={{
                              style: {
                                height: '84',
                                padding: '1rem',
                                color: 'grey',
                              },
                            }}
                            onChange={(e) => {
                              let s_time = moment(e.target.value, ["HH:mm"]).format("hh:mm A");
                              console.log("s_time", s_time, e.target.value)
                              updateFormValue(i, s_time)
                            }}
                            sx={{ width: 150 }} focused />
                        }
                      </>
                    ))
                  }

                </div>
              }

              {

                assets.length > 0 ?
                  <div
                    className="template_assets_form"
                  >
                    <ul>
                      {
                        assets.map((x: any) => (
                          <li> <a href={x.AssetFile_Url} target="_blank" >{x.Asset_Nm}</a> </li>
                        ))
                      }

                    </ul>
                    <label htmlFor="">{t('Assets')}</label>
                  </div>
                  : null
              }
            </div>
            {linkCharLimitError ? (
                <span className="dyn-templ-error">
                  {t("Link is too long")}
                </span>
              ) : null
            }

            {/* <br /> */}
            <GenericButton label={t("Send")}
              type="submit"
              disable={disabled || templateId == 0 || error || isNumberLandline || othersPhone.length==0}
              loading={loading} />
          </div>

          <div style={mobileStyle ? { height: '326px', width: '100%' } : {}}></div>
        </form>
      </div>
      <ConfirmPopup
        show={showBlockedContactConfirmPopup}
        confirmButtonText={t('Yes')}
        cancelButtonText={t('No')}
        text={t('This number is blocked, do you want to continue?')}
        clickConfirmButtonHandler={() => {
          sendMessage();
        }}
        clickCancelButtonHandler={() => {
          setShowBlockedContactConfirmPopup(false);
        }}
        disabled={false}
      />
      <LogoutModal
        show={overrideModal}
        yorn={overrideFunction}
        modalText={overrideMessage}

        handleCloseParent={(e) => {
          setOverrideModal(false)
        }} />
    </div>
  );
}

